import React from 'react';
import { SelectedFilters, ReactiveList, ResultList } from '@appbaseio/reactivesearch';
import Collapsible from './Collapsible';
import ResultItem, { resultItemDetails } from '../styles/ResultItem';

const onResultStats = (results, time) => (
  <div className="flex justify-end">
    {results} results found in {time} ms
  </div>
);
const onData = (data) => (
  <ResultItem key={data.uuid}>
    <Collapsible title={require('html-react-parser')(data.title + ' [published on ' + data.publication_date +']')}>
      <div>{require('html-react-parser')(''+data.copy)}</div>
    </Collapsible> 
  </ResultItem>
);
const Results = () => (
  <div className="result-list">
    <SelectedFilters className="m1" />
    <ReactiveList
      componentId="results"
      dataField="publication_date"
      onData={onData}
      onResultStats={onResultStats}
      react={{
        and: ['mainSearch','copySearch','titleGroupSearch','dateSensor']
      }}
      pagination
      renderItem={onData}
      innerClass={{
        list: 'result-list-container',
        pagination: 'result-list-pagination',
        resultsInfo: 'result-list-info',
        poweredBy: 'powered-by',
      }}
      size={10}
    />
  </div>
);
export default Results;

