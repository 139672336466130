import React, { Component } from 'react';
import { ReactiveBase,DataSearch, ResultList, DateRange, ReactiveList, MultiDropdownList, ReactiveComponent } from '@appbaseio/reactivesearch';
import Results from './components/Results';
//import CustomDateRange from './components/CustomDateRange'
import GalNetDateRange from './components/GalNetDateRange'
import "./App.css"
import "./main.css"

class App extends Component {
  render() {
    return (
      <div>    
        <ReactiveBase
          app="galnet2"
          url="https://cssd-dev-es-1.digitalresources.jisc.ac.uk/galnet"
         >
        <div class="columns">
            <div class="column">
               <DataSearch
                componentId="mainSearch"
                dataField={["title"]}
                className="search-bar"
                queryFormat="and"
                placeholder="Search in titles..."
                iconPosition="left"
                autosuggest={false}
                filterLabel="search"
                highlight={true}
                customHighlight={(props) => ({
                  highlight: {
                    force_source : true,
                      pre_tags: ['<mark>'],
                      post_tags: ['</mark>'],
                      fields: {
                      "title" : {},
                      "copy" : {}
                      },
                      number_of_fragments: 0
                  }
                })}
              />
            </div>
            <div class="column">
              <DataSearch
                componentId="copySearch"
                dataField={["copy","title"]}
                className="search-bar"
                queryFormat="and"
                placeholder="Search in articles..."
                iconPosition="left"
                autosuggest={false}
                filterLabel="search"
                highlight={true}
                customHighlight={(props) => ({
                  highlight: {
                    force_source : true,
                      pre_tags: ['<mark>'],
                      post_tags: ['</mark>'],
                      fields: {
                        "copy" : {},
                        "title" : {}
                      },
                      number_of_fragments: 0
                  }
                })}
              />
	        </div>
            <div class="column">
               <MultiDropdownList
                 componentId="titleGroupSearch"
                 dataField="title.kw_i"
                 title="Title types"
                 showCount={true}
                 showFilter={true}
                 showSearch={true}
                 size={20}
               />
            </div>

            <div class="column">
                <ReactiveComponent
                    componentId="dateSensor"
                    render = {({setQuery}) => (
                        <GalNetDateRange setQuery={setQuery} />
                    )}
                 />
            </div>
        </div>
        <Results />
        </ReactiveBase>
    </div>
    );
  }
}
export default App;


