import React, { Component } from "react";
import { ReactiveComponent } from '@appbaseio/reactivesearch';
import PropTypes from 'prop-types';

class GalNetDateRange extends React.Component {

    constructor(props) {
        super(props);
        this.handleStartChange = this.handleStartChange.bind(this);
        this.handleEndChange = this.handleEndChange.bind(this);
        this.resetDate = this.resetDate.bind(this);
        this.setValue = this.setValue.bind(this);
        this.setState = this.setState.bind(this);
        this.fieldName = 'publication_date'
        this.state = {
            firstDate: '3301-01-01',
            lastDate: '3305-12-12'
        }
        console.log(props);
    };


    handleStartChange(event) {
        this.setState({
            firstDate: event.target.value
        })
        //alert(this.firstDate)
    }

    handleEndChange(event) {
        this.setState({
            lastDate: event.target.value
        })
        //alert(this.lastDate);
    }

    resetDate = (start, end) => {
        this.setState({
            firstDate: '3301-01-01',
            lastDate: '3305-12-12'
         })

        start = this.state.firstDate;
        end = this.state.lastDate;
        const publication_date = 'publication_date';

		this.props.setQuery({
		    query : {
		        bool : {
		            must : [
		                {
				            range: {
					            [publication_date] : {
					                gte: start,
					                lte: end,
					            },
				            },
				        },
				    ]
				}
			}
		});
    };

    setValue(start,end, title) {
        //alert("SV",start,end)
        start = this.state.firstDate;
        end = this.state.lastDate;
        const publication_date = 'publication_date';

		this.props.setQuery({
		    query : {
		        bool : {
		            must : [
		                {
				            range: {
					            [publication_date] : {
					                gte: start,
					                lte: end,
					            }
				            }
				        }
				    ]
				}
			}
		});
	}
    render() {
        return (
            <div>
                <span>Start&nbsp;</span><input type="text" value={this.state.firstDate} onChange={this.handleStartChange} /><br/>
                <span>End&nbsp;&nbsp;&nbsp;</span><input type="text" value={this.state.lastDate} onChange={this.handleEndChange} />
                <button onClick={() => this.setValue(this.firstDate, this.lastDate, 'title')}>Set</button>
                <button onClick={() => this.resetDate()}>ReSet</button>
            </div>
        );
  }

}

/*GalNetDateRange.propTypes = {
  childId: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired
};*/

export default GalNetDateRange;